<template>
  <div class="d-flex align-items-center justify-content-between" style="position: relative;">
    <div id="tour-search" class="search__box pl-2 border-bottom" :class="className">
      <div class="search__icon">
        <feather-icon class="fw-bold" size="25" icon="SearchIcon" />
      </div>
      <div class="search__text">
        <b-form-input ref="searchProduct" id="search-product" v-model="filter.search" class="focus-0" type="text"
          placeholder="Cari Produk" @focus="className = 'bg-white'" @blur="onBlur" autofocus />
      </div>
      <div v-if="filter.search" class="align-self-center p-1" role="button" @click="filter.search = '', className = ''"
        id="button--clear__filter">
        <feather-icon size="18" icon="XIcon" />
      </div>
    </div>
    <div v-if="filter.search" class="search__item p-2" style="overflow-y: scroll;"
      :class="!isLoading && result && result.data && result.data.length == 0 ? 'd-flex align-items-center justify-content-center' : 'd-block'">
      <h6 v-if="is_add_child" class="text-dark text-darken-7 fw-bold-700 size14 mb-1">
        Template Produk dari Toqoo
      </h6>
      <div v-if="isLoading" class="d-flex justify-content-center mb-1 mt-5">
        <b-spinner label="Loading..." />
      </div>
      <div v-if="!isLoading">
        <div class="product--lists">
          <div class="d-flex flex-wrap">
            <div class="product--cards" v-for="(item, index) in result.data" :key="index">
              <div class="product--card"
                @click.stop="item.is_default == true ? detailProductDefault(item) : detailProduct(item.uuid)">
                <div class="position-relative">
                  <b-img class="product--image" :src="item.image_url" :alt="item.alias_name" />
                  <div class="product--detail">
                    <h6 class="product--title">{{ item.alias_name || '-' }}</h6>
                    <div class="product--price">
                      <span class="text-white font-weight-bold bg-primary">{{ item.standard_price | formatAmount
                        }}</span>
                    </div>
                    <div class="product--unit">
                      <span class="text-white font-weight-bold bg-info">{{ item.stock }} {{ item.unit }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center"
          v-if="result && result.data && result.data.length > 0 && result.next_page_url != null">
          <b-spinner v-if="isLoadingLoadMore" class="me-2" label="Loading..." />
          <b-button @click="loadMoreProduct" id="button--load__more">
            Load More
          </b-button>
        </div>
      </div>
      <div v-if="!isLoading && result && result.data && result.data.length == 0">
        <div class="d-flex justify-content-center align-items-center">
          <div class="text-center">
            <h6 class="h5 text-dark fw-bold-800 size14 mt-2">
              <!-- Pilih barang untuk dimasukan keranjang -->
              Oops!
            </h6>
            <h6 class="text-dark fw-bold-800 size14">
              Hasil tidak ditemukan.
            </h6>
            <!-- <b-button @click="filter.page = 1, $bvModal.show('modal-add-product')" v-if="checkPermission('add produk')">
              Tambah Produk
            </b-button> -->
          </div>
        </div>
      </div>
    </div>

    <div class="w-25 pr-1" style="position: absolute; right: 0;">
      <v-select id="item_id" v-model="item_id" label="name" :options="optionItems" :reduce="item => item.label"
        :create-option="item => ({ name: item, label: item })" placeholder="Type to search item..." taggable
        @search="onSearchItem">
        <template slot="no-options">
          Type here to search item...
        </template>
      </v-select>
    </div>

    <ModalEditProduct v-if="filter.search" :key="1" :modal-key="'search'" :result="resultDetail"
      :form-data="formPayload" :create-item-to-cart="createItemToCart" :messages="messages" :loading="isLoading"
      :is-default="isDefault" @typeWarehouse="getTypeWarehouse" @payload="getPayload" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BFormInput, BImg, BCard, BCardBody, BSpinner, BButton, BModal, BContainer, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification, preformatFloat } from '@/auth/utils'
import ModalEditProduct from '@/components/Pos/Modal/AddProductToCart.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormInput,
    BImg,
    BCard,
    BCardBody,
    BSpinner,
    ModalEditProduct,
    BButton,
    BModal,
    BContainer,
    BFormCheckbox,
    BRow,
    BCol,
    vSelect,
  },
  props: {
    is_add: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification, preformatFloat
    }
  },
  data() {
    return {
      totalBuy: this.$store.state.cashier.totalBuy,
      isLoading: false,
      isLoadingEdit: false,
      className: '',
      is_add_child: false,
      result: {},
      filter: {
        search: '',
        per_page: 10,
      },
      formPayload: {
        delivery_numbers_uuid: [],
        po_so_uuid: '',
        product_uuid: '',
        warehouse_uuid: '',
        qty: 0,
        price: 0,
        discounts: [],
        is_bonus: 0,
      },
      resultDetail: {},
      messages: '',
      id: '',
      typeWarehouse: '',
      isDefault: false,
      warehouses: [],
      formPayloadCheck: {
        product_type: 'parent',
        alias_name: '',
        uom_uuid: '',
        buying_price: null,
        minimum_price: null,
        standard_price: null,
        initial_stocks: [
          {
            warehouse_uuid: '',
          },
        ],
      },
      next_page_url: '',
      optionUom: [],
      isLoadingProduct: false,
      isLoadingLoadMore: false,
      page: 2,
      optionItems: [],
      item_id: null
    }
  },
  watch: {
    '$store.state.cashier.totalBuy': function (value) {
      const vm = this
      vm.totalBuy = value
    },
    is_add(value) {
      this.is_add_child = value
    },
    is_add_child(value) {
      this.$emit('getIsAdd', value)
    },
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
    item_id(item) {
      this.$store.commit('item/setItemId', item)
    }
  },
  mounted() {
    this.$refs.searchProduct.focus()
  },
  methods: {
    onSearchItem(search, loading) {
      if (search.length) {
        loading(true)
        this.searchItem(loading, search, this)
      }
    },
    searchItem: _.debounce((loading, search, vm) => {
      vm.$store.dispatch('item/getData', {
        params: {
          search: encodeURI(search),
        },
      })
        .then(response => {
          vm.optionItems = response.data.data.data.map(item => ({
            label: item.formatted_id,
            name: item.name,
          }));
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),

    getTypeWarehouse(value) {
      this.typeWarehouse = value
    },
    getPayload(value) {
      this.formPayload = value
    },
    onBlur() {
      if (this.filter.search.length === 0) {
        this.className = ''
        this.is_add_child = false
        this.$store.commit('cart/setKey', 0)
      }
    },
    createItem() {
      if (this.resultDetail.is_default) {
        this.formPayloadCheck.product_uuid = this.resultDetail.uuid
        this.formPayloadCheck.alias_name = this.resultDetail.alias_name
        this.formPayloadCheck.uom_uuid = this.resultDetail.unit_uuid
      }

      const form = this.preparePayloadProduct()
      if (!this.resultDetail.is_default) {
        form.append('uom_uuid', this.formPayloadCheck.uom_uuid.label || this.formPayloadCheck.uom_uuid.name || this.formPayloadCheck.uom_uuid)
      } else {
        form.append('uom_uuid', this.resultDetail.unit_uuid)
      }

      this.isLoadingProduct = true
      this.$store.dispatch('parentProduct/postData', {
        query: '',
        payload: form,
      })
        .then(async () => {
          await this.getData()
          await this.clearWarehouse()
          successNotification(this, 'Success', 'Produk berhasil di buat')
          // this.filter.search = ''
          this.formPayloadCheck = {
            ...this.formPayloadCheck,
            product_type: 'parent',
            alias_name: '',
            uom_uuid: '',
          }
          this.isLoadingProduct = false
          this.$bvModal.hide('modal-add-product')
        })
        .catch(() => {
          this.isLoadingProduct = false
        })
    },
    clearWarehouse() {
      for (let index = 0; index < this.warehouses.length; index++) {
        this.formPayloadCheck.initial_stocks[index].warehouse_uuid = ''
      }
    },
    async createItemToCart() {
      this.isLoading = true
      if (this.$store.state.cart.isEdit === false) {
        this.formPayload.product_uuid = this.id
      }
      if (this.typeWarehouse === 'warehouse') {
        delete this.formPayload.po_so_uuid
        delete this.formPayload.delivery_numbers_uuid
      }
      if (!this.typeWarehouse) {
        delete this.formPayload.po_so_uuid
        delete this.formPayload.delivery_numbers_uuid
      }
      if (this.isDefault === true) {
        delete this.formPayload.po_so_uuid
        delete this.formPayload.delivery_numbers_uuid
      }
      const form = this.preparePayload()
      await this.$store.dispatch(`cart/${this.$store.state.cart.isEdit === true ? 'postEditItemToCart' : 'postAddItemToCart'}`, {
        uuid: this.$store.state.cart.isEdit === false ? this.$store.state.cart.cartUuid : this.$store.state.cart.itemUuid,
        payload: form,
      }).then(() => {
        successNotification(this, 'Success', `Produk berhasil ${this.$store.state.cart.isEdit === false ? 'ditambahkan' : 'diubah'} ke cart`)
        this.getActiveCart()
        this.isLoading = false
        this.formPayload = {}
        this.$bvModal.hide('modal-add-product-to-cart-search')
        this.$store.commit('cart/setIsEdit', false)
        this.filter.search = ''
      }).catch(err => {
        // eslint-disable-next-line no-console
        this.isLoading = false
        this.messages = err.response.data.meta.messages
      })
    },
    async getData() {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = 1
      queryParams.with_delivery_numbers = 1

      await this.$store.dispatch('cashier/getData', {
        uuid: '',
        params: queryParams,
      }).then(result => {
        this.result = result.data.data
        this.isLoading = false
        // eslint-disable-next-line no-console
      }).catch(err => {
        this.isLoading = false
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    async loadMoreProduct() {
      this.isLoadingLoadMore = true
      const queryParams = this.filter
      queryParams.with_delivery_numbers = 1
      // eslint-disable-next-line no-plusplus
      queryParams.page = this.page++

      await this.$store.dispatch('cashier/getData', {
        uuid: '',
        params: queryParams,
      }).then(result => {
        this.result.next_page_url = result.data.data.next_page_url
        // Array.prototype.push.apply(this.result, result.data.data)
        this.result.data = [...this.result.data, ...result.data.data.data]
        this.isLoadingLoadMore = false
        // eslint-disable-next-line no-console
      }).catch(err => {
        this.isLoadingLoadMore = false
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    detailProductDefault(item) {
      this.resultDetail = item
      this.$emit('item', { item, type: 'search' })
    },
    async detailProduct(id) {
      this.isDefault = false
      this.isLoadingEdit = true
      this.$store.commit('cart/setIsEdit', false)
      this.formPayload.product_uuid = id
      this.id = id
      await this.$store.dispatch('cashier/getData', {
        uuid: `/${id}`,
        params: {
          with_delivery_numbers: 1
        },
      }).then(result => {
        const item = result.data.data
        this.resultDetail = item
        this.formPayload = {
          is_bonus: 0,
          price: item.standard_price || '0',
          discounts: item.discounts || [],
        }
        this.isLoadingEdit = false
        this.$bvModal.show('modal-add-product-to-cart-search')
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    async getActiveCart() {
      await this.$store.dispatch('cart/getCart', {
        uuid: '',
        params: '',
      }).then(result => {
        const item = result.data.data
        this.$store.commit('cart/setDiscountCart', item.discount_value)
        this.$store.commit('cart/setDiscountType', item.discount_type)
        this.$store.commit('cart/setDiscountTotal', item.discount_total)
        this.$store.commit('cart/setSubtotalCart', item.subtotal)
        this.$store.commit('cart/setTotalPrice', item.total)
        this.$store.commit('cart/setTotalBuy', item.items.length)
        this.$store.commit('cart/setCartUuid', item.uuid)
        this.$store.commit('cart/setResultCartActive', item)
        this.$store.commit('cashier/setProduct', item.items.length)
        localStorage.setItem('cartUuid', item.uuid)
        localStorage.setItem('cash_total_payment', item.total)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          if (key !== 'price' && key !== 'qty' && key !== 'discounts' && key !== 'delivery_numbers_uuid' && key !== 'warehouse_uuid' && key !== 'supplier') {
            form.append(key, this.formPayload[key])
          }
          if (key === 'price' || key === 'qty') {
            // eslint-disable-next-line radix
            form.append(key, parseFloat(this.preformatFloat(this.formPayload[key].toString())))
          }
        }
      }
      if (this.typeWarehouse === 'warehouse') {
        form.append('warehouse_uuid', this.formPayload.warehouse_uuid)
      }
      if (this.typeWarehouse === 'supplier') {
        form.append('supplier_uuid', this.formPayload.warehouse_uuid)
      }
      if (this.formPayload.discounts.length) {
        for (let index = 0; index < this.formPayload.discounts.length; index++) {
          const element = this.formPayload.discounts[index]
          // eslint-disable-next-line no-restricted-syntax
          for (const key in element) {
            if (Object.hasOwnProperty.call(element, key)) {
              const items = element[key]
              form.append(`discounts[${index}][${key}]`, items)
            }
          }
        }
      }
      if (this.formPayload.delivery_numbers_uuid) {
        for (let index = 0; index < this.formPayload.delivery_numbers_uuid.length; index++) {
          const element = this.formPayload.delivery_numbers_uuid[index]
          form.append('delivery_numbers_uuid[]', element.label)
        }
      }
      return form
    },
    preparePayloadProduct() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayloadCheck) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayloadCheck.hasOwnProperty(key) && this.formPayloadCheck[key] != null) {
          if (key !== 'initial_stocks' && key !== 'buying_price' && key !== 'minimum_price' && key !== 'standard_price' && key !== 'uom_uuid') {
            form.append(key, this.formPayloadCheck[key])
          }
          if (key === 'buying_price' || key === 'minimum_price' || key === 'standard_price') {
            form.append(key, parseFloat(this.preformatFloat(this.formPayloadCheck[key])))
          }
        }
      }
      if (this.formPayloadCheck.initial_stocks) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.formPayloadCheck.initial_stocks.length; index++) {
          const element = this.formPayloadCheck.initial_stocks[index]
          if (element.warehouse_uuid !== '' && element.warehouse_uuid !== false) {
            // eslint-disable-next-line no-restricted-syntax
            for (const key in element) {
              if (Object.hasOwnProperty.call(element, key)) {
                // eslint-disable-next-line no-use-before-define
                const items = element[key]
                form.append(`initial_stocks[${index}][${key}]`, items)
              }
            }
          }
        }
      }
      return form
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.search__item {
  position: absolute;
  z-index: 100;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
  height: calc(100vh - 76px);
  top: 80px;

  .card {
    box-shadow: none;
    border: 1px solid #E4E5EC;
    border-radius: 12px;
  }
}

.image__container {
  margin-right: 1rem;

  img {
    width: 47px;
    height: 47px;
    object-fit: cover;
    border-radius: 8px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/variables/_variables.scss';

.vs__dropdown-toggle input {
  font-weight: 500 !important;
}

#modal-add-product {
  .modal-dialog {
    .modal-content {
      background: #FCFCFC;
      border-radius: 16px;

      .modal-body {
        padding: 0;

        .form-group {
          label {
            font-size: 14px;
            color: $dark;
            font-weight: 600;
          }

          input {
            background: #E7E7ED;
            border: none;
            border-radius: 8px;
            height: 52px;
            color: $dark;
            font-weight: 600;
          }
        }

        .button__group {
          display: flex;
          width: 100%;

          .button__cancel {
            background-color: #FFFFFF !important;
            border-color: #fff !important;
            color: $dark !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 0px 16px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

          .button__save {
            background-color: #45B6AB !important;
            border-color: #45B6AB !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 16px 0px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

        }
      }
    }
  }
}
</style>
